<!--Main Navigation-->
<!--Main Navigation-->
<app-sidebar></app-sidebar>
<header>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark pink scrolling-navbar">
        <a class="navbar-brand" href="javascript:void(0)" (click)="CheckRoute('/dashboard')"><strong>MONITORING</strong></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item dropdown">
                    <a class="link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown">ANALYTICS </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a class=" dropdown-item" href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Analytics/channels')">Channels API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item " href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Analytics/programs')">Programs API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Analytics/languages')">Languages API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item " href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Analytics/locations')">Locations API</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown">WATCHO </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a class=" dropdown-item" href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Watcho/webSeries')">Web Series API </a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item " href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Watcho/videoPlayer')">Youbora API </a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item " href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Watcho/activeSubscriptions')">Active Subscription API </a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item " href="javascript:void(0)" routerLinkActive="active"
                                (click)="CheckRoute('/Watcho/kalturaLogin')">Kaltura Login API </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown">
                        OFFERS & PLANS CP</a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a class=" dropdown-item" (click)="CheckRoute('/WatchoOffersAndPlans/zee5')"
                                routerLink="/WatchoOffersAndPlans/zee5">Zee5 API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" (click)="CheckRoute('/WatchoOffersAndPlans/sony')"
                                routerLink="/WatchoOffersAndPlans/zee5">Sony API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" (click)="CheckRoute('/WatchoOffersAndPlans/klikk')"
                                routerLink="/WatchoOffersAndPlans/zee5">Klikk API</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown">OFFERS & PLANS
                        IT</a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a class=" dropdown-item" routerLinkActive="active"
                            (click)="CheckRoute('/WatchoOffersAndPlans/GetActiveSubscriptions')">Get Active Subscription API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" routerLinkActive="active"
                            (click)="CheckRoute('/WatchoOffersAndPlans/GetActiveSubscriptionsLogin')">Get Active Subscription(Login) API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" routerLinkActive="active"
                            (click)="CheckRoute('/WatchoOffersAndPlans/GetPrepaidBalance')">Get Prepaid Balance API</a>
                        </li>
                        <li class="nav-item">
                            <a class=" dropdown-item" routerLinkActive="active"
                            (click)="CheckRoute('/WatchoOffersAndPlans/GetSubscriptionHistory')">Get Subscription History API</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="margin-right: 90px;">
                <li class="nav-item dropdown">
                    <a class="link dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-user"></i> </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a class=" dropdown-item" (click)="logout()">Logout </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</header>