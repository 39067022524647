<div class="row" >
    <div class="col-md-4 mx-auto my-5">
      <section class="form-elegant" >
        <mdb-card>
          <mdb-card-body class="mx-4">
            <!--Header-->
            <div class="text-center">
              <h3 class="dark-grey-text mb-5">
                <strong>SIGN IN</strong>
              </h3>
            </div>
  
            <form [formGroup]="elegantForm">
              <div class="md-form">
                <input type="text" id="elegantFormEmailEx" class="form-control" formControlName="email" mdbInput>
                <label for="elegantFormEmailEx" style="margin-top: -10px;">Your Email</label>
              </div>
  
              <div class="md-form pb-3">
                <input type="password" id="elegantFormPasswordEx" formControlName="password" class="form-control" mdbInput>
                <label for="elegantFormPasswordEx" style="margin-top: -10px;">Your Password</label>
                <!-- <p class="font-small blue-text d-flex justify-content-end">Forgot
                  <a href="#" class="blue-text ml-1"> Password.?</a>
                </p> -->
              </div>
            </form>
            <!--Body-->
  
            <div class="text-center mb-3">
              <button type="button" mdbBtn gradient="blue" block="true" rounded="true" class="z-depth-1a waves-light" mdbWavesEffect (click)="checkLogin()">Sign in</button>
            </div>
          </mdb-card-body>
          <!--Footer-->
        </mdb-card>
  
      </section>
    </div>
  </div>