<app-header></app-header>
<div class="card margin">
    <h2>News Channels API</h2>
    <table class="table">
        <tbody>
            <tr>
                <th scope="col">API Name</th>
                <td>http://analytics.mysmartstick.com/api/v1/analytics/channel/news</td>
            </tr>
            <tr>
                <th scope="col">Description</th>
                <td>This API is used to fetch all news channel data along with the number of users per day.<br>
                    Start and end parameters specifying the limit in terms of date and time are passed between which the data is fetched.<br>
                    This API is currenty being used in Elite Panel Analyics Project. </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="card mt-3" style="height: 400px;">
    <div class="card-body">
        <div class="chart-wrapper canvas">
            <!-- <button mdbRipple type="button" (click)="getDownloadData()" class="btn btn-success buttonCss">DOWNLOAD</button> -->
            <canvas baseChart class="chart" [datasets]="mainChartData" [plugins]="barChartPlugins"
                [labels]="mainChartLabels" [options]="mainChartOptions" [colors]="mainChartColours"
                [legend]="mainChartLegend" [chartType]="mainChartType"></canvas>
        </div>
    </div>
</div>
<ngx-spinner size="medium" type="ball-spin">
    <p class="loading">Loading...</p>
  </ngx-spinner>